// LOGOUT REASONS

/**
 * @typedef {string} AuthLogoutReason
 * @access public
 */

const EXPIRED_TOKEN = 'LOGOUT_REASON/EXPIRED_TOKEN';
const FORCED_LOGOUT = 'LOGOUT_REASON/FORCED_LOGOUT';
const USER_LOGOUT = 'LOGOUT_REASON/USER_LOGOUT';

/**
 * @name AuthLogoutReasons
 * @access public
 * @property {AuthLogoutReason} FORCED_LOGOUT Forced logout by backend (revoking token).
 * @property {AuthLogoutReason} EXPIRED_TOKEN Expired token (reached expiration date).
 * @property {AuthLogoutReason} USER_LOGOUT User manually logged out.
 */
export const AuthLogoutReasons = {
  EXPIRED_TOKEN,
  FORCED_LOGOUT,
  USER_LOGOUT,
};
