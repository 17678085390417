import {
  configureStore as toolkitConfigureStore,
  getDefaultMiddleware,
} from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import reducer from '../ducks/RootReducer';
import saga from '../middlewares/sagas/RootSaga';

import { isDev } from '../../utils/env';

const sagaMiddleware = createSagaMiddleware();

const middleware = [...getDefaultMiddleware({ thunk: false }), sagaMiddleware];

const configureStore = (preloadedState) => {
  const store = toolkitConfigureStore({
    reducer,
    devTools: isDev(),
    middleware,
    preloadedState,
  });
  sagaMiddleware.run(saga);
  return store;
};
export const defaultStore = configureStore({});

export default configureStore;
