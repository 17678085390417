import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from '../../redux/ducks/SnackbarDucks';

export const useNotifications = () => {
  const dispatch = useDispatch();

  const closeSnackbar = useCallback(
    (...args) => dispatch(closeSnackbarAction(...args)),
    [dispatch]
  );

  const enqueueSnackbar = useCallback(
    (notification) => {
      dispatch(
        enqueueSnackbarAction({
          ...notification,
        })
      );
    },
    [dispatch]
  );

  const handleDismissAll = useCallback(() => {
    closeSnackbar();
  }, [closeSnackbar]);

  return {
    enqueueSnackbar,
    closeSnackbar,
    handleDismissAll,
  };
};

export default useNotifications;
