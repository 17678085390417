import { differenceInMinutes, parse, startOfDay } from 'date-fns';
import { hours, minutes } from '../constants/time';

export const generateHours = () => {
  const hoursArray = [];
  hours.map((h) =>
    minutes.map((m) => hoursArray.push({ hours: h, minutes: m }))
  );
  return hoursArray;
};

export const minutesFromMidnight = ({ hours, minutes }) => {
  const selected = parse(`${hours}:${minutes}`, 'HH:mm', new Date());
  const startDay = startOfDay(selected);
  const minutesFromMidnight = differenceInMinutes(selected, startDay);
  return minutesFromMidnight;
};

export const generateList = () => {
  const hoursArray = generateHours();
  const formattedArray = hoursArray.map(({ hours, minutes }) => {
    const formattedHour = hours > 12 ? hours - 12 : hours;
    const dayPeriod = hours > 12 ? 'pm' : 'am';

    const hour = `0${formattedHour}`.slice(-2);
    const minute = `0${minutes}`.slice(-2);

    return {
      label: `${hour}:${minute} ${dayPeriod}`,
      value: minutesFromMidnight({ hours, minutes }),
    };
  });
  return formattedArray;
};
