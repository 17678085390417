import ErrorHandler, { Providers } from '@eatzy/common-reactjs/ErrorHandler';
import Environment from '@eatzy/common-reactjs/Environment';

const errorHandler = new ErrorHandler(Providers.Bugsnag, {
  apiKey: Environment.getEnv('BUGSNAG_API_KEY'),
  environment: Environment.getEnv('EATZY_ENVIRONMENT'),
});

errorHandler.start();
export const ErrorBoundary = errorHandler.getErrorBoundary();

export default errorHandler;
